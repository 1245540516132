const orderType = [
  { text: "全部", id: null },
  { text: "随手拍", id: 1 },
];
const stateType = [
  { text: "全部", id: null },
  { text: "待受理", id: 1 },
  { text: "已接单", id: 2 },
  { text: "处理中", id: 3 },
  { text: "已完成", id: 4 },
];
export { orderType, stateType };
